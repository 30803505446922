import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass/styled-components';

import SEO from '../components/SEO';
import { Container, Header } from '../styles/PageLayout';
import { Headline, Subtitle } from '../styles/Typography';
import StudentPortalButton from '../components/StudentPortalButton';
import Logo from '../components/Logo';

const Link = styled.a`
  font-weight: bold;
  text-decoration: underline;
`;

const SuccessPage = () => (
  <>
    <SEO title="Ironhack Alumni Platform Migration" />
    <Header>
      <Logo />
    </Header>
    <Container>
      <Box textAlign="center">
        <Headline>You already have access to the new Student Portal</Headline>
        <Box maxWidth="31em" mt="1em" color="#717291">
          <Subtitle>
            <strong>
              Remember to bookmark the url of the plaform:
              https://my.ironhack.com
            </strong>
          </Subtitle>
          <Box mt="2em">
            <StudentPortalButton />
          </Box>
        </Box>
      </Box>
    </Container>
  </>
);

export default SuccessPage;
