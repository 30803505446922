import React from 'react';
import styled from 'styled-components';

import { device } from '../constants/breakpoints';

const Button = styled.button`
  width: 100%;
  height: 3em;
  font: 14px Roboto;
  font-weight: bold;
  background: #2dc5fa;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  @media ${device.tablet} {
    font: 20px Roboto;
    font-weight: bold;
  }
`;

function StudentPortalButton(props) {
  return (
    <a href="https://my.ironhack.com" target="_blank" rel="noopener noreferrer">
      <Button>Go To Your Student Portal</Button>
    </a>
  );
}

export default StudentPortalButton;
